import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {faWindowClose, faFileImage} from '@fortawesome/free-solid-svg-icons';
import {FestivalService} from '../shared/services/festival.service';
import {ActivatedRoute} from '@angular/router';
import {Festival} from '../shared/models/festival.model';
import {ManageDataService} from '../shared/services/manage-data.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, OnDestroy {
  /**
   * Title is the visible text on the view
   */
  @Input() title: string;
  @Input() userID: string; // use this to attach a file to a user via dashboard
  @Input() subdomain: string; // use this to upload image to new festival
  @ViewChild('fileInput')
  fileInput: ElementRef;
  uploadProgressSubscription: Subscription;
  uploadResponseSubscription: Subscription;
  uploadResponse;
  uploadProgress: number;
  selectedFile = null;
  error: string;
  faWindowClose = faWindowClose;
  faFileImage = faFileImage;
  festival: Festival;

  constructor(
    private festivalService: FestivalService,
    private route: ActivatedRoute,
    private dataService: ManageDataService) {
  }

  ngOnInit(): void {
    this.uploadProgress = 1;
    this.uploadProgressSubscription = this.dataService.uploadProgressSubject.subscribe(percent => {
      this.uploadProgress = percent;
      /*if (percent === 0) {
        this.resetFileInput();
      }*/
    });

    this.uploadResponseSubscription = this.dataService.uploadResponse.subscribe(uploadResponse => {
      if (uploadResponse) {
        this.uploadResponse = uploadResponse.body;
      }
    });
  }

  resetFileInput(): void {
    if (this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  onFileSelected(event): void {
    this.error = null;
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const allowedFormats = ['jpg', 'png', 'jpeg', 'webp', 'svg'];
      const fileType = selectedFile.name.split('.').pop();
      if (allowedFormats.includes(fileType.toLowerCase())) {
        this.selectedFile = selectedFile;
        const subdomain = this.festival ? this.festival.festivalEnglishName : this.subdomain;
        if (subdomain) {
          this.dataService.onFileUpload(subdomain, selectedFile, this.userID);
        }
      } else {
        this.error = 'ניתן להעלות קבצים מסוג:  ' + allowedFormats.join(', ');
      }
    }
  }

  ngOnDestroy(): void {
    this.uploadProgressSubscription.unsubscribe();
    this.uploadResponseSubscription.unsubscribe();
  }

}
