<form [formGroup]="addForm" (ngSubmit)="onSubmit()">
  <label>
    <span>מספר מזהה של יוזמה</span>
    <input type="number" formControlName="initiativeID" class="form-control" placeholder="נא להזין מספר מזהה של יוזמה">
  </label>
  <label>
    <span>מספר מזהה של מקום</span>
    <input type="number" formControlName="placeID" class="form-control" placeholder="נא להזין מספר מזהה של מקום">
  </label>
  <div class="text-center mt-3 mb-3">
    <button class="btn btn-warning font-size-18 pr-3 pl-3" type="button" (click)="loadPlaceData()" [disabled]="!addForm.controls.initiativeID.value || !addForm.controls.placeID.value">טעינת מידע</button>
  </div>

  <div class="alert alert-danger" *ngIf="loadedInitiative && !initiative">לא נמצאה יוזמה עם המספר המזהה שהוקלד</div>
  <div class="alert alert-danger" *ngIf="loadedPlace && !place">לא נמצא מקום עם המספר המזהה שהוזן</div>

  <div *ngIf="initiative && place">

    <div *ngIf="initiative.festivalEnglishName===place.festivalEnglishName">
      <span>הוספת אירוע לפסטיבל </span>
      <span>{{place.festivalName}}</span>
    </div>

    <div *ngIf="initiative.festivalEnglishName!==place.festivalEnglishName">היוזמה והמקום לא משוייכים לאותו הפסטיבל</div>

    <div *ngIf="initiative.festivalEnglishName===place.festivalEnglishName">
      <div class="row">
        <div class="col data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faFlag"></fa-icon>
            <span>בחרת ביוזמה</span>
          </div>
          <div class="image-container mt-4 text-center" [style.backgroundImage]="'url('+ initiative.path +')'">
            <div class="after p-3 text-right">
              <div class="image-text-box">
                <div class="font-size-17 font-weight-bold">{{initiative.title}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col data-box">
          <div class="col-12 font-size-14 font-weight-bold border-bottom">
            <fa-icon class="ml-2 color-kinda-light-grey" [icon]="faHome"></fa-icon>
            <span>בחרת במקום</span>
          </div>
          <div class="image-container mt-4 text-center" [style.backgroundImage]="'url('+ place.path +')'">
            <div class="after p-3 text-right">
              <div class="image-text-box">
                <div class="font-size-17 font-weight-bold">{{place.description}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 text-center font-size-17">
        <div class="col pb-3 select-date-box pointer" *ngFor="let item of dateRange" (click)="selectDate(item.fullDate)" [class.selected-date]="item.fullDate===selectedDate">
          <div>{{item.day}}</div>
          <div>{{item.dayMonth}}</div>
        </div>
      </div>
      <div class="row select-hours-box mt-5 justify-content-center">
        <div class="hour mr-2 mb-5 p-2 pointer"
             *ngFor="let hour of hours"
             (click)="selectHour(hour, true)"
             [class.new-selected-hour]="newSelectedDate === selectedDate && newSelectedHour===hour"
             [class.selected-hour]="isSelectedHour(hour)"
             [class.hour-unavailable]="isHourUnavailable(hour)">
          <img *ngIf="eventOccursAtHour(hour)" class="event-flag" src="/assets/event-flag.svg" alt="event flag">
          {{hour}}
        </div>
      </div>
      <div class="text-center mt-5">
        <button class="btn btn-submit font-size-18 pr-3 pl-3" type="submit" [disabled]="!selectedDate || !selectedHour">שמירה</button>
      </div>
    </div>
  </div>
</form>
