<div>
  <app-list-title
    title="רשימת פסטיבלים"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    subTitle="{{totalText}}"
    filteredResults="{{numberOfFilteredResults}}">
  </app-list-title>

  <div class="d-flex align-items-center justify-content-space-between">
    <div class="d-flex">
      <form class="d-flex w-100" [formGroup]="filterForm">
        <label>
          <select formControlName="communityID" class="form-control" (change)="onFilter()">
            <option value="">בחירת קהילה</option>
            <option *ngFor="let item of communities" [ngValue]="item.communityID">{{item.title}}</option>
          </select>
        </label>
        <label>
          <select formControlName="city" class="form-control" (change)="onFilter()">
            <option value="">בחירת יישוב</option>
            <option *ngFor="let item of cities" [ngValue]="item.title">{{item.title}}</option>
          </select>
        </label>
        <label>
          <select formControlName="archived" class="form-control" (change)="onFilter()">
            <option value="">בחירת סטטוס</option>
            <option *ngFor="let item of statuses" [ngValue]="item.value">{{item.title}}</option>
          </select>
        </label>
      </form>
      <fa-icon class="pointer font-size-20 ml-5 color-red" *ngIf="filtered" [icon]="faFilter" (click)="clearFilter()"></fa-icon>
      <div>
        <label>
          <input type="text" [(ngModel)]="search" class="form-control" (ngModelChange)="countSearchResults()">
        </label>
      </div>
    </div>
    <app-add-item-button addItemLink="/add-cultural-initiative"></app-add-item-button>
  </div>

  <table class="table text-center">
    <tr>
      <td class="pointer" (click)="sort('festivalID')">
        <fa-icon [icon]="faSort"></fa-icon>
        <span>מספר מזהה</span>
      </td>
      <td class="pointer" (click)="sort('name')">
        <fa-icon [icon]="faSort"></fa-icon>
        <span>שם</span>
      </td>
      <td class="pointer" (click)="sort('fromDate')">
        <fa-icon [icon]="faSort"></fa-icon>
        <span>תאריכים</span>
      </td>
      <td class="pointer" (click)="sort('subdomain')">
        <fa-icon [icon]="faSort"></fa-icon>
        <span>סאבדומיין</span>
      </td>
      <td class="pointer" (click)="sort('city')">
        <fa-icon [icon]="faSort"></fa-icon>
        <span>יישוב</span>
      </td>
      <td>לוגו</td>
      <td class="pointer" (click)="sort('archived')">
        <fa-icon [icon]="faSort"></fa-icon>
        <span>סטטוס</span>
      </td>
      <td>פעולות</td>
    </tr>
    <tr *ngFor="let item of culturalInitiatives | filterCulturalInitiativesListByObject: filterByObject | filterCulturalInitiativesList:search | orderBy: key: reverse">
      <td>{{item.festivalID}}</td>
      <td>{{item.name}}</td>
      <td>
        <span>{{formatFromDate(item.fromDate)}}</span>
        <span>/</span>
        <span>{{formatUntilDate(item.untilDate)}}</span>
      </td>
      <td>{{item.subdomain}}</td>
      <td>{{item.city}}</td>
      <td>
        <img class="logo" src="https://api.hanadiv.org/images/{{item.logo}}" alt="logo">
      </td>
      <td class="font-weight-bold" [ngClass]="item.archived?'text-danger':'text-success'">{{item.archived ? 'לא פעיל' : 'פעיל'}}</td>
      <!--<td>{{item.insertTimeStamp}}</td>-->
      <td class="d-flex justify-content-space-evenly">
        <a class="btn btn-success" routerLink="/cultural-initiative/{{item.subdomain}}">
          <fa-icon [icon]="faEye"></fa-icon>
        </a>
        <a class="btn btn-info" routerLink="/edit-cultural-initiative/{{item.subdomain}}">
          <fa-icon [icon]="faEdit"></fa-icon>
        </a>
      </td>
    </tr>
  </table>
</div>
