<div *ngIf="culturalInitiative">
  <app-page-title
    title="{{title}}"
    subTitle="{{subTitle}}"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    rowID="{{culturalInitiative.festivalID}}"
    createDate="{{culturalInitiative.insertTimeStamp}}"></app-page-title>
  <form [formGroup]="editCulturalInitiativeForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-8">
        <div class="data-box">
          <label for="name" class="title">שם הפסטיבל</label>
          <input id="name" type="text" class="form-control" placeholder="שם" formControlName="name">
        </div>

        <!-- navbar tabs for text fields -->
        <div class="data-box">
          <ul class="nav justify-content-start">
            <li class="nav-item pointer">
              <a class="nav-link" [class.font-weight-bold]="tabs.about" (click)="showTab('about')">תיאור הפסטיבל</a>
            </li>
            <li class="nav-item pointer">
              <a class="nav-link" [class.font-weight-bold]="tabs.navigation" (click)="showTab('navigation')">דרכי הגעה</a>
            </li>
            <li class="nav-item pointer">
              <a class="nav-link" [class.font-weight-bold]="tabs.team" (click)="showTab('team')">צוות הפסטיבל</a>
            </li>
            <li class="nav-item pointer">
              <a class="nav-link" [class.font-weight-bold]="tabs.thanks" (click)="showTab('thanks')">תודות</a>
            </li>
            <li class="nav-item pointer">
              <a class="nav-link" [class.font-weight-bold]="tabs.sleep" (click)="showTab('sleep')">לינה</a>
            </li>
            <li class="nav-item pointer" [class.font-weight-bold]="tabs.updates">
              <a class="nav-link" (click)="showTab('updates')">עדכונים</a>
            </li>
          </ul>
          <div *ngIf="tabs.about">
            <app-edit-cultural-initiative-about content="{{editCulturalInitiativeForm.controls.about.value}}" (newItemEvent)="listenToTextEditorEmits('about', $event)"></app-edit-cultural-initiative-about>
          </div>
          <div *ngIf="tabs.navigation">
            <app-edit-cultural-initiative-navigation content="{{editCulturalInitiativeForm.controls.navigation.value}}" (newItemEvent)="listenToTextEditorEmits('navigation', $event)"></app-edit-cultural-initiative-navigation>
          </div>
          <div *ngIf="tabs.team">
            <app-edit-cultural-initiative-team content="{{editCulturalInitiativeForm.controls.team.value}}" (newItemEvent)="listenToTextEditorEmits('team', $event)"></app-edit-cultural-initiative-team>
          </div>
          <div *ngIf="tabs.thanks">
            <app-edit-cultural-initiative-thanks content="{{editCulturalInitiativeForm.controls.thanks.value}}" (newItemEvent)="listenToTextEditorEmits('thanks', $event)"></app-edit-cultural-initiative-thanks>
          </div>
          <div *ngIf="tabs.sleep">
            <app-edit-cultural-initiative-sleep content="{{editCulturalInitiativeForm.controls.sleep.value}}" (newItemEvent)="listenToTextEditorEmits('sleep', $event)"></app-edit-cultural-initiative-sleep>
          </div>
          <div *ngIf="tabs.updates">
            <app-edit-cultural-initiative-updates content="{{editCulturalInitiativeForm.controls.updates.value}}" (newItemEvent)="listenToTextEditorEmits('updates', $event)"></app-edit-cultural-initiative-updates>
          </div>
        </div>

        <!-- festival categories -->
        <div class="data-box">
          <div class="title">קטגוריות</div>
          <div class="row">
            <div class="col genre-box justify-content-between" *ngFor="let item of culturalInitiative.genres">
              <div>{{item.title}}</div>
              <fa-icon class="color-kinda-greyish pointer" (click)="removeGenre(item.genreID)" [icon]="faTrash"></fa-icon>
            </div>
            <div class="col genre-box pointer" (click)="openAddGenreModal(addGenreModal)">
              <div class="ml-2 color-turquoise">הוספת קטגוריה</div>
              <fa-icon class="color-turquoise" [icon]="faPlus"></fa-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="data-box">
          <div class="title">סאבדומיין</div>
          <label class="mt-3">
            <input type="text" class="form-control" placeholder="סאבדומיין" formControlName="subdomain">
          </label>
        </div>
        <div class="data-box">
          <div class="title">יישוב</div>
          <label>
            <select class="form-control" formControlName="cityID">
              <option value="0">יישוב</option>
              <option *ngFor="let city of cities" [ngValue]="city.cityID">{{ city.title }}</option>
            </select>
          </label>
        </div>
        <div class="data-box">
          <div class="title">סטטוס</div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="archived" id="festival-not-active" value="true">
            <label class="form-check-label" for="festival-not-active">לא פעיל</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="archived" id="festival-active" value="false">
            <label class="form-check-label" for="festival-active">פעיל</label>
          </div>
        </div>
        <div class="data-box">
          <div class="title">לוגו</div>
          <img class="logo" src="https://api.hanadiv.org/images/{{editCulturalInitiativeForm.controls.fileHash.value}}" alt="לוגו">
          <app-file-upload title="החלפת תמונה" subdomain="{{culturalInitiative.subdomain}}"></app-file-upload>
        </div>
        <div class="data-box text-center">
          <div class="title">תאריכים</div>
          <div class="title">מתאריך</div>
          <ngb-datepicker #fromDate formControlName="fromDate" (navigate)="date = $event.next"></ngb-datepicker>
          <div class="title">עד תאריך</div>
          <ngb-datepicker #untilDate formControlName="untilDate" (navigate)="date = $event.next"></ngb-datepicker>
        </div>
        <div class="data-box">
          <div class="title">רשתות חברתיות</div>
          <div>
            <div class="d-flex justify-content-end mb-1">
              <img class="social-icon-logo" src="assets/facebook.svg" alt="facebook logo">
            </div>
            <label>
              <input type="text" class="form-control" formControlName="facebook" placeholder="facebook.com">
            </label>
          </div>
          <div>
            <div class="d-flex justify-content-end mb-1">
              <img class="social-icon-logo" src="assets/instagram.svg" alt="instagram logo">
            </div>
            <label>
              <input type="text" class="form-control" formControlName="instagram" placeholder="instagram.com">
            </label>
          </div>
          <div>
            <div class="d-flex justify-content-end mb-1">
              <img class="social-icon-logo" src="assets/youtube.svg" alt="youtube logo">
            </div>
            <label>
              <input type="text" class="form-control" formControlName="youtube" placeholder="youtube.com">
            </label>
          </div>
        </div>
        <div class="data-box mt-3">
          <div class="title">קידום</div>
          <div>
            <label>
              <textarea class="form-control" formControlName="metadata"></textarea>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-5 mb-10">
      <button class="btn btn-submit font-size-18 pr-3 pl-3" type="submit">עדכון פרטי היוזמה התרבותית</button>
    </div>
  </form>
</div>

<ng-template #addGenreModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">הוספת קטגוריה</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="addGenreForm" (ngSubmit)="onSubmitAddGenreForm();modal.close()">
      <label class="mt-3">
        <input type="text" class="form-control" placeholder="קטגוריה" formControlName="genre">
      </label>
      <div class="modal-footer mt-3">
        <button class="col btn btn-dismiss font-size-22" type="button" (click)="modal.close()">ביטול</button>
        <button class="col btn btn-submit font-size-22" type="submit">הוספה</button>
      </div>
    </form>
    <div *ngIf="addGenreForm.value['genre'].length>2">
      <div class="pointer" *ngFor="let item of genres | filterGenres:addGenreForm.value['genre']" (click)="addGenreFromList(item);modal.close()">{{item.title}}</div>
    </div>
  </div>
</ng-template>
