import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ManageInitiative} from '../shared/models/manage-initiative.model';
import {ManagePlace} from '../shared/models/manage-place.model';
import {InitiativeService} from '../shared/services/initiative-service.service';
import {ManagePlaceService} from '../shared/services/manage-place-service.service';
import {faFlag, faHome} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import {ManageEventService} from '../shared/services/manage-event.service';
import {Router} from '@angular/router';
import {ManageEvent} from '../shared/models/manage-event.model';
import {ManageDataService} from '../shared/services/manage-data.service';


@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: [
    '../shared/styles/list.css',
    '../shared/styles/forms-style.css',
    './event-form.component.css'
  ]
})
export class EventFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() eventData: ManageEvent;
  addForm: FormGroup;
  initiativesSubscription: Subscription;
  placesSubscription: Subscription;
  createEventSubscription: Subscription;
  initiative: ManageInitiative;
  place: ManagePlace;

  faFlag = faFlag;
  faHome = faHome;

  dateRange = [];
  selectedDate;
  selectedHour;
  newSelectedHour;
  newSelectedDate;
  hours: string[];
  placeAvailability = {};

  editMode: boolean;
  loadedInitiative: boolean;
  loadedPlace: boolean;
  submitted: boolean;

  constructor(
    private initiativesService: InitiativeService,
    private placesService: ManagePlaceService,
    private eventsService: ManageEventService,
    private manageDataService: ManageDataService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.eventData) {
      this.editMode = true;
      this.addForm.controls.initiativeID.setValue(this.eventData.initiativeID);
      this.addForm.controls.placeID.setValue(this.eventData.placeID);
      this.loadPlaceData();
    }
  }

  buildForm(): void {
    this.addForm = new FormGroup({
      initiativeID: new FormControl('', [Validators.required]),
      placeID: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      hour: new FormControl('', [Validators.required]),
    });
  }

  loadPlaceData(): void {
    this.loadedInitiative = false;
    this.loadedPlace = false;
    this.dateRange = [];
    this.getPlace();
    this.getInitiative();
  }

  getPlace(): void {
    const placeID = this.addForm.controls.placeID.value;
    if (placeID) {
      this.placesSubscription = this.placesService.get(placeID).subscribe(place => {
        this.loadedPlace = true;
        this.place = place[0];
        if (this.place) {
          this.buildDates();
          this.setPlaceAvailableHours();
        }
      });
    }
  }

  getInitiative(): void {
    const initiativeID = this.addForm.controls.initiativeID.value;
    if (initiativeID) {
      this.initiativesSubscription = this.initiativesService.get(initiativeID).subscribe(initiative => {
        this.loadedInitiative = true;
        this.initiative = initiative[0];
      });
    }
  }

  buildDates(): void {
    this.selectedDate = moment(this.place.festivalFromDate);
    for (const m = moment(this.place.festivalFromDate); m.diff(this.place.festivalUntilDate, 'days') <= 0; m.add(1, 'days')) {
      const fullDate = m.format('YYYY-MM-DD');
      this.setDataObject(fullDate);
      this.dateRange.push({
        day: m.locale('he').format('dddd'),
        dayMonth: m.format('DD/MM'),
        fullDate
      });
    }
    this.selectDate(this.place.festivalFromDate);
  }

  selectDate(date): void {
    this.selectedDate = date;
    const placeAvailableDateTime = this.place.availableDatetime.find(item => item.date === this.selectedDate);
    this.hours = placeAvailableDateTime.hours;
  }

  selectHour(hour, modifyNewSelectedHour): void {
    this.selectedHour = hour;
    if (modifyNewSelectedHour) {
      this.newSelectedDate = this.selectedDate;
      this.newSelectedHour = hour;
      this.addForm.controls.date.setValue(this.newSelectedDate);
      this.addForm.controls.hour.setValue(this.newSelectedHour);
    } else {
      this.addForm.controls.date.setValue(this.selectedDate);
      this.addForm.controls.hour.setValue(this.selectedHour);
    }
  }

  setDataObject(dateString): void {
    this.placeAvailability[dateString] = {hours: []};
  }

  isSelectedHour(hour): boolean {
    if (this.editMode && this.selectedDate && this.eventData && this.selectedHour) {
      return this.selectedDate === this.eventData.eventDate && !this.newSelectedDate && !this.newSelectedHour && this.selectedHour === hour;
    }
    return false;
  }

  isHourUnavailable(hour): boolean {
    const notAvailableDate = this.place.placeIsNotAvailableAtDateTime.find(item => item.date === this.selectedDate);
    if (notAvailableDate) {
      return notAvailableDate?.fromHours.includes(hour) || notAvailableDate?.blockedHours.includes(hour) || false;
    }
    return false;
  }

  setPlaceAvailableHours(): void {
    this.place.availableDatetime.map(item => {
      const formattedDate = moment(item.date).format('YYYY-MM-DD');
      this.placeAvailability[formattedDate].hours = item.hours;
    });

    if (this.editMode) {
      this.selectDate(this.eventData.eventDate);
      this.selectHour(this.eventData.eventHour, false);
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.addForm.status === 'VALID') {
      if (this.editMode) {
        this.createEventSubscription = this.eventsService.updateEvent(this.initiative.festivalEnglishName, this.addForm.value).subscribe(res => {
          if (res.status === true) {
            this.router.navigate(['/events-list']);
          }
        });
      } else {
        this.createEventSubscription = this.eventsService.createEvent(this.initiative.festivalEnglishName, this.addForm.value).subscribe(res => {
          if (res.status === true) {
            this.router.navigate(['/events-list']);
          }
        });
      }
    }
  }

  eventOccursAtHour(hour): boolean {
    // selectedDate && eventData && selectedDate === eventData.eventDate && hour === eventData.eventHour
    if ((this.eventData || this.place.events.length > 0) && this.selectedDate) {
      const eventsForDay = this.place.events.find(eventsDayList => eventsDayList[0].date === this.selectedDate);
      if (eventsForDay) {
        const foundEvent = eventsForDay.find(item => item.fromHour === hour);
        return !!foundEvent;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.placesSubscription) {
      this.placesSubscription.unsubscribe();
    }
    if (this.initiativesSubscription) {
      this.initiativesSubscription.unsubscribe();
    }
    if (this.createEventSubscription) {
      this.createEventSubscription.unsubscribe();
    }
  }
}

