<div>
  <app-list-title title="הוספת פסטיבל" image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"></app-list-title>
  <form [formGroup]="addCulturalInitiativeForm" (submit)="onSubmit()">
    <div class="row data-box">
      <div class="col-6">
        <label>
          <span class="title">שם הפסטיבל</span>
          <input type="text" class="form-control" formControlName="name">
        </label>
      </div>
      <div class="col-6">
        <label>
          <span class="title">סאבדומיין</span>
          <input type="text" class="form-control" formControlName="subdomain">
        </label>
      </div>
      <div class="col-6">
        <label>
          <span class="title">קהילה</span>
          <select class="form-control" formControlName="communityID">
            <option value="0">בחירת קהילה</option>
            <option *ngFor="let item of communities" [ngValue]="item.communityID">{{item.title}}</option>
          </select>
        </label>
      </div>
      <div class="col-6">
        <label>
          <span class="title">יישוב</span>
          <select class="form-control" formControlName="cityID">
            <option value="0">בחירת יישוב</option>
            <option *ngFor="let item of cities" [ngValue]="item.cityID">{{item.title}}</option>
          </select>
        </label>
      </div>
      <div class="col-6">
        <label>
          <span class="title">פייסבוק</span>
          <input type="text" class="form-control" formControlName="facebook">
        </label>
      </div>
      <div class="col-6">
        <label>
          <span class="title">אינסטגרם</span>
          <input type="text" class="form-control" formControlName="instagram">
        </label>
      </div>
      <div class="col-6">
        <label>
          <span class="title">יוטיוב</span>
          <input type="text" class="form-control" formControlName="youtube">
        </label>
      </div>
    </div>
    <div class="data-box" *ngIf="addCulturalInitiativeForm.controls.subdomain.value">
      <div class="col">
        <app-file-upload [title]="'העלאת לוגו הפסטיבל'" subdomain="{{addCulturalInitiativeForm.controls.subdomain.value}}"></app-file-upload>
        <div class="col m-auto text-center" *ngIf="imageSource">
          <img [src]="imageSource" class="logo rounded img-thumbnail" alt="לוגו פסטיבל">
        </div>
      </div>
    </div>

    <!-- dates -->
    <div class="data-box d-flex justify-content-space-evenly text-center">
      <div>
        <div class="title">מתאריך</div>
        <ngb-datepicker #fromDate formControlName="fromDate" (navigate)="date = $event.next"></ngb-datepicker>
      </div>
      <div>
        <div class="title">עד תאריך</div>
        <ngb-datepicker #untilDate formControlName="untilDate" (navigate)="date = $event.next"></ngb-datepicker>
      </div>
    </div>

    <!-- navbar tabs for text fields -->
    <div class="data-box">
      <ul class="nav justify-content-start">
        <li class="nav-item pointer">
          <a class="nav-link" [class.font-weight-bold]="tabs.about" (click)="showTab('about')">תיאור הפסטיבל</a>
        </li>
        <li class="nav-item pointer">
          <a class="nav-link" [class.font-weight-bold]="tabs.navigation" (click)="showTab('navigation')">דרכי הגעה</a>
        </li>
        <li class="nav-item pointer">
          <a class="nav-link" [class.font-weight-bold]="tabs.team" (click)="showTab('team')">צוות הפסטיבל</a>
        </li>
        <li class="nav-item pointer">
          <a class="nav-link" [class.font-weight-bold]="tabs.thanks" (click)="showTab('thanks')">תודות</a>
        </li>
        <li class="nav-item pointer">
          <a class="nav-link" [class.font-weight-bold]="tabs.sleep" (click)="showTab('sleep')">לינה</a>
        </li>
        <li class="nav-item pointer" [class.font-weight-bold]="tabs.updates">
          <a class="nav-link" (click)="showTab('updates')">עדכונים</a>
        </li>
      </ul>
      <div *ngIf="tabs.about">
        <app-edit-cultural-initiative-about content="{{addCulturalInitiativeForm.controls.about.value}}" (newItemEvent)="listenToTextEditorEmits('about', $event)"></app-edit-cultural-initiative-about>
      </div>
      <div *ngIf="tabs.navigation">
        <app-edit-cultural-initiative-navigation content="{{addCulturalInitiativeForm.controls.navigation.value}}" (newItemEvent)="listenToTextEditorEmits('navigation', $event)"></app-edit-cultural-initiative-navigation>
      </div>
      <div *ngIf="tabs.team">
        <app-edit-cultural-initiative-team content="{{addCulturalInitiativeForm.controls.team.value}}" (newItemEvent)="listenToTextEditorEmits('team', $event)"></app-edit-cultural-initiative-team>
      </div>
      <div *ngIf="tabs.thanks">
        <app-edit-cultural-initiative-thanks content="{{addCulturalInitiativeForm.controls.thanks.value}}" (newItemEvent)="listenToTextEditorEmits('thanks', $event)"></app-edit-cultural-initiative-thanks>
      </div>
      <div *ngIf="tabs.sleep">
        <app-edit-cultural-initiative-sleep content="{{addCulturalInitiativeForm.controls.sleep.value}}" (newItemEvent)="listenToTextEditorEmits('sleep', $event)"></app-edit-cultural-initiative-sleep>
      </div>
      <div *ngIf="tabs.updates">
        <app-edit-cultural-initiative-updates content="{{addCulturalInitiativeForm.controls.updates.value}}" (newItemEvent)="listenToTextEditorEmits('updates', $event)"></app-edit-cultural-initiative-updates>
      </div>
    </div>
    <div class="text-center mt-5">
      <button class="btn btn-submit font-size-18 pr-3 pl-3 w-75" type="submit">שמירה</button>
    </div>
  </form>
</div>
