<div *ngIf="users">
  <app-list-title
    title="חברים/ות"
    image="https://api.hanadiv.org/assets/Icons_Profile_Grey.svg"
    subTitle="{{totalText}}"
    filteredResults="{{numberOfFilteredResults}}">
  </app-list-title>

  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center">
      <form class="d-flex w-100" [formGroup]="filterForm">
        <label class="filter-label" *ngIf="user.manageSystem || user.manageCommunity">
          <select formControlName="festival" class="form-control" (change)="onFilter()">
            <option value="">בחירת פסטיבל</option>
            <option *ngFor="let item of festivals" [ngValue]="item.name">{{item.name}}</option>
          </select>
        </label>
        <label class="filter-label">
          <select formControlName="city" class="form-control" (change)="onFilter()">
            <option value="">בחירת יישוב</option>
            <option *ngFor="let item of cities" [ngValue]="item.title">{{item.title}}</option>
          </select>
        </label>
        <label class="filter-label">
          <select formControlName="status" class="form-control" (change)="onFilter()">
            <option value="">בחירת סטטוס</option>
            <option *ngFor="let item of statuses" [ngValue]="item.title">{{item.title}}</option>
          </select>
        </label>
      </form>
      <fa-icon class="pointer font-size-20 ml-5 color-red" *ngIf="filtered" [icon]="faFilter" (click)="clearFilter()"></fa-icon>
    </div>
    <div>
      <label>
        <input type="text" [(ngModel)]="search" class="form-control" (ngModelChange)="countSearchResults()">
      </label>
    </div>
  </div>

  <div class="container-fluid">
    <table class="table text-center">
      <tr>
        <td class="pointer" (click)="sort('userID')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>מס' מזהה</span>
        </td>
        <td class="pointer" (click)="sort('email')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>אימייל</span>
        </td>
        <td class="pointer" (click)="sort('firstName')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>שם פרטי</span>
        </td>
        <td class="pointer" (click)="sort('lastName')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>שם משפחה</span>
        </td>
        <td>
          <span>טלפון</span>
        </td>
        <td>
          <span>סטטוס</span>
        </td>
        <td class="pointer" (click)="sort('city')">
          <fa-icon [icon]="faSort"></fa-icon>
          <span>עיר</span>
        </td>
        <td *ngIf="user.manageSystem || user.manageCommunity">
          <span>פסטיבלים</span>
        </td>
        <td *ngIf="user.manageSystem || user.manageCommunity">
          <span>קהילה</span>
        </td>
        <td class="w-25">פעולות</td>
      </tr>
      <tr *ngFor="let item of users | filterUsersListByObject:filterByObject | filterUsersList:search | orderBy: key: reverse">
        <td>{{item.userID}}</td>
        <td>{{item.email}}</td>
        <td>{{item.firstName}}</td>
        <td>{{item.lastName}}</td>
        <td>{{item.phone}}</td>
        <td>{{item.status}}</td>
        <td>{{item.city}}</td>
        <td>{{item.festivals}}</td>
        <td>{{item.communities}}</td>
        <td class="d-flex justify-content-space-evenly">
          <a class="btn btn-success" routerLink="/user/{{item.userID}}">
            <fa-icon [icon]="faEye"></fa-icon>
          </a>
          <a class="btn btn-info" routerLink="/edit-user/{{item.userID}}">
            <fa-icon [icon]="faEdit"></fa-icon>
          </a>
          <!--<button class="btn btn-danger" (click)="delete(item.userID)">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>-->
        </td>
      </tr>
    </table>
  </div>
</div>
